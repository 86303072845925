import React from "react"
import { FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
import {
  Col,
  Container,
  Row,
  Form,
  FormControl,
  Button,
  Spinner,
} from "react-bootstrap"
import { listCountries, fetchSMSPricing } from "../../request"

class SMSCalculator extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      selectedCurrency: "eur",
      countries: [],
      isProcessing: false,
      selectedCountry: null,
      country: "NL",
      hasCountryError: false,
      serverError: false,
      serverErrorMessage: null,
      results: null,
    }
  }

  componentDidMount() {
    if (!localStorage.getItem("notificareSelectedCurrency")) {
      localStorage.setItem("notificareSelectedCurrency", "eur")
    }

    this.setState({
      selectedCurrency: localStorage.getItem("notificareSelectedCurrency"),
    })

    //Because storage event is never triggered in the same page
    //We need to keep an interval to check for changes in currency
    this.intervalId = setInterval(() => {
      this.setState({
        selectedCurrency: localStorage.getItem("notificareSelectedCurrency"),
      })
    }, 1000)

    //Get a list of countries
    listCountries().then((results) => {
      let country = results.data.countries.find((c) => {
        return c.alpha2_code === this.state.country
      })

      this.setState({
        selectedCountry: country,
        countries: results.data.countries,
      })
    })
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  handleInputChange = (e) => {
    e.persist()
    if (e && e.target && e.target.name) {
      this.setState({
        [e.target.name]: e.target.value,
      })
    }

    let country = this.state.countries.find((c) => {
      return c.alpha2_code === e.target.value
    })

    this.setState({
      selectedCountry: country,
      hasCountryError: false,
      serverError: false,
      serverErrorMessage: null,
      results: null,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.setState({
      isProcessing: true,
      hasCountryError: false,
      serverError: false,
      serverErrorMessage: null,
      results: null,
    })

    if (!this.state.country) {
      this.setState({
        isProcessing: false,
        hasCountryError: true,
      })
    } else {
      fetchSMSPricing(this.state.country)
        .then((results) => {
          this.setState({
            isProcessing: false,
            hasCountryError: false,
            results: results.data,
          })
        })
        .catch((e) => {
          this.setState({
            isProcessing: false,
            serverError: true,
            serverErrorMessage:
              e && e.response && e.response.data && e.response.data.error
                ? e.response.data.error
                : null,
          })
        })
    }
  }

  displayLongCodeSmsOutboundPrice = () => {
    if (this.state.results.price) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayLongCodeSmsInboundPrice = () => {
    if (this.state.results.inbound_price) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayLongCodeMmsOutboundPrice = () => {
    if (this.state.results.mms_price) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayLongCodeMmsInboundPrice = () => {
    if (this.state.results.mms_inbound_price) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayTollFreeSmsOutboundPrice = () => {
    if (this.state.results.price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayTollFreeSmsInboundPrice = () => {
    if (this.state.results.inbound_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayTollFreeMmsOutboundPrice = () => {
    if (this.state.results.mms_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayTollFreeMmsInboundPrice = () => {
    if (this.state.results.mms_inbound_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayShortCodeSmsOutboundPrice = () => {
    if (this.state.results.short_code_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.short_code_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayShortCodeSmsInboundPrice = () => {
    if (this.state.results.inbound_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayShortCodeMmsOutboundPrice = () => {
    if (this.state.results.mms_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayShortCodeMmsInboundPrice = () => {
    if (this.state.results.mms_inbound_price && this.isUSOrCanada()) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.mms_inbound_price[this.state.selectedCurrency]
      return (
        <div className={`sms-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
        </div>
      )
    } else {
      return (
        <div className={`sms-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayLongCodePrice = () => {
    if (this.state.results.number_price) {
      let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
        value = this.state.results.number_price[this.state.selectedCurrency]
      return (
        <div className={`sms-nr-price`}>
          <span className={`currency-symbol`}>{currency}</span>
          {value}
          <span className={`periodicity`}>
            <FormattedMessage id="components.smsCalculator.results.perMonth" />
          </span>
        </div>
      )
    } else {
      return (
        <div className={`sms-nr-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayTollFreePrice = () => {
    if (this.state.results?.number_prices?.length > 1 && this.isUSOrCanada()) {
      let n = this.state.results?.number_prices.find((n) => {
        return n.type === "toll-free";
      });
      if (n) {
        let currency = this.state.selectedCurrency === "eur" ? "€" : "$",
          value = n.price[this.state.selectedCurrency]
        return (
          <div className={`sms-nr-price`}>
            <span className={`currency-symbol`}>{currency}</span>
            {value}
            <span className={`periodicity`}>
            <FormattedMessage id="components.smsCalculator.results.perMonth" />
          </span>
          </div>
        )
      } else {
        return (
          <div className={`sms-nr-price na`}>
            <FormattedMessage id="components.smsCalculator.results.na" />
          </div>
        )
      }
    } else {
      return (
        <div className={`sms-nr-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  displayShortCodePrice = () => {
    if (this.isUSOrCanada()) {
      return (
        <div className={`sms-nr-price`}>
          <FormattedMessage id="components.smsCalculator.results.sales" />
        </div>
      )
    } else {
      return (
        <div className={`sms-nr-price na`}>
          <FormattedMessage id="components.smsCalculator.results.na" />
        </div>
      )
    }
  }

  isUSOrCanada = () => {
    return this.state.country === 'US' || this.state.country === 'CA';
  }

  render() {
    return (
      <div className={`sms-calculator`}>
        <Container>
          <div className={`calculator-box`}>
            <Row noGutters={true}>
              <Col md={4}>
                <div className={`side-bar`}>
                  <div className={`title`}>
                    <FormattedMessage id="components.smsCalculator.title" />
                  </div>
                  <div className={`text`}>
                    <FormattedMessage id="components.smsCalculator.text" />
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className={`calculator-form`}>
                  <Form className={`form-area`} onSubmit={this.handleSubmit}>
                    <Row>
                      <Col lg={8}>
                        <div className={`form-field-group`}>
                          <Form.Label>
                            <FormattedMessage id="components.smsCalculator.country.label" />
                          </Form.Label>
                          <div className={`select-container country-selector`}>
                            {this.state.selectedCountry && (
                              <div className={`country-flag`}>
                                <img
                                  alt={this.state.selectedCountry.name}
                                  src={this.state.selectedCountry.flag}
                                />
                              </div>
                            )}
                            <FormControl
                              as={`select`}
                              value={this.state.country}
                              name={`country`}
                              className="form-field"
                              onChange={this.handleInputChange}
                            >
                              {this.state.countries.map((country, i) => {
                                return (
                                  <option key={i} value={country.alpha2_code}>
                                    {country.name}
                                  </option>
                                )
                              })}
                            </FormControl>
                          </div>
                          {this.state.hasCountryError && (
                            <>
                              <div className={`feedback-text error`}>
                                <FormattedMessage id="components.smsCalculator.country.error" />
                              </div>
                            </>
                          )}
                          {this.state.serverError && (
                            <>
                              <div className={`feedback-text error`}>
                                {this.state.serverErrorMessage}
                              </div>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col lg={4}>
                        <Button
                          className={`form-button inline calculator-button`}
                          disabled={this.state.isProcessing}
                          variant="secondary"
                          type="submit"
                          block
                        >
                          {this.state.isProcessing && (
                            <Spinner
                              as="span"
                              size="sm"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                          <FormattedMessage id="components.smsCalculator.button.label" />
                        </Button>
                      </Col>
                    </Row>
                    {this.state.results && (
                      <div className={`calculator-results`}>

                        <div className={`results-table-wrapper`}>
                          <table className={`results-table`}>
                            <thead>
                            <tr>
                              <th className={`hbig`}><FormattedMessage id="components.smsCalculator.results.type"/></th>
                              <th className={`hsmall center`}>
                                <FormattedMessage id="components.smsCalculator.results.sms"/>
                                <div className={`subtitle`}>
                                  <FormattedMessage id="components.smsCalculator.results.outbound"/>
                                </div>
                              </th>
                              <th className={`hsmall center`}>
                                <FormattedMessage id="components.smsCalculator.results.sms"/>
                                <div className={`subtitle`}>
                                  <FormattedMessage id="components.smsCalculator.results.inbound"/>
                                </div>
                              </th>
                              <th className={`hsmall center`}>
                                <FormattedMessage id="components.smsCalculator.results.mms"/>
                                <div className={`subtitle`}>
                                  <FormattedMessage id="components.smsCalculator.results.outbound"/>
                                </div>
                              </th>
                              <th className={`hsmall center`}><FormattedMessage
                                id="components.smsCalculator.results.mms"/>
                                <div className={`subtitle`}>
                                  <FormattedMessage id="components.smsCalculator.results.inbound"/>
                                </div>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.longCode.title"/></div>
                              </td>
                              <td className={`center`}>{this.displayLongCodeSmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayLongCodeSmsInboundPrice()}</td>
                              <td className={`center`}>{this.displayLongCodeMmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayLongCodeMmsInboundPrice()}</td>
                            </tr>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.tollFree.title"/></div>
                              </td>
                              <td className={`center`}>{this.displayTollFreeSmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayTollFreeSmsInboundPrice()}</td>
                              <td className={`center`}>{this.displayTollFreeMmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayTollFreeMmsInboundPrice()}</td>
                            </tr>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.shortCode.title"/></div>
                              </td>
                              <td className={`center`}>{this.displayShortCodeSmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayShortCodeSmsInboundPrice()}</td>
                              <td className={`center`}>{this.displayShortCodeMmsOutboundPrice()}</td>
                              <td className={`center`}>{this.displayShortCodeMmsInboundPrice()}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className={`results-table-wrapper mt-4`}>
                          <table className={`results-table`}>
                            <thead>
                            <tr>
                              <th><FormattedMessage id="components.smsCalculator.results.numberPricing"/></th>
                              <th className={`hmedium center`}><FormattedMessage
                                id="components.smsCalculator.results.cost"/>
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.longCode.title"/></div>
                                <div className={`number-description`}><FormattedMessage
                                  id="components.smsCalculator.results.longCode.description"/>
                                </div>
                              </td>
                              <td className={`center`}>{this.displayLongCodePrice()}</td>
                            </tr>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.tollFree.title"/></div>
                                <div className={`number-description`}><FormattedMessage
                                  id="components.smsCalculator.results.tollFree.description"/>
                                </div>
                              </td>
                              <td className={`center`}>{this.displayTollFreePrice()}</td>
                            </tr>
                            <tr>
                              <td>
                                <div className={`number-title`}><FormattedMessage
                                  id="components.smsCalculator.results.shortCode.title"/></div>
                                <div className={`number-description`}><FormattedMessage
                                  id="components.smsCalculator.results.shortCode.description"/>
                                </div>
                              </td>
                              <td className={`center`}>{this.displayShortCodePrice()}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                        <Row>
                          <Col md={12}>
                            <div
                              className={`disclaimer`}
                              dangerouslySetInnerHTML={{
                                __html: this.props.intl.formatMessage({
                                  id: "components.smsCalculator.results.disclaimer",
                                }),
                              }}
                            ></div>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    )
  }
}

export default injectIntl(SMSCalculator)
